import React, { useContext, useEffect, useState } from "react";
import Button from '../../Button/Button'
import style from './Contato.module.css'
import styleС from '../Contato/Contato.module.css'
import Title from "../../Title";
import { QtdContext } from "../../../context/qtdContext";
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import RadioButton from './Radio/RadioButton'
import { BACKURL } from '../../../conf.js'
import Loading from 'react-fullscreen-loading';
import ButtonsModal from "../../Modal/ButtonsModal/ButtonsModal.js";
import ButtonsModalVes from "../../Modal/ButtonsModal/ButtonsModalVes.js";

import ModalDatePicker from "../../Modal/ModalDatePicker.js";
// import DatePicker from 'react-datepicker';
// import 'react-datepicker/dist/react-datepicker.css';
// import { addDays, isWeekend } from 'date-fns';
import { registerLocale, setDefaultLocale } from "react-datepicker";
import { ru } from 'date-fns/locale/ru';
registerLocale('ru', ru)
const Order = () => {
  var url = "https://cleaner.dadata.ru/api/v1/clean/address";
  var token = "${API_KEY}";
  var secret = "${SECRET_KEY}";
  var query = "москва сухонская 11";
  const delay = (ms) => {
    return new Promise((r) => setTimeout(() => r(), ms));
  };
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [minBuy, setMinBuy] = useState(1500)
  const [ref, setRef] = useState(null);
  const [tomorrow, settomorrow] = useState(1);
  // const [produtosSelecionados, setConfirmedCart] = useState(null);

  const [paytaped, setpaytaped] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);
  const [adressConfirm, setAdressConfirm] = useState('Не подтверждено');
  // const [selectedDate, setSelectedDate] = useState(null);
  const [error1, setError1] = useState({
    email: false,
    ref: false
  });
  const [errorPromo, setErrorPromo] = useState(false);
  const [bonus, setBonus] = useState(0);
  const [bonusALL, setBonusALL] = useState(0);
  const [timebonus, setTimeBonus] = useState(0);
  const [asked, setAsked] = useState(false);
  const [bonusTaked, setBonusTaked] = useState(false);
  const [adressCoordrs, setadressCoordrs] = useState(null);

  const [promo, setPromo] = useState(0);
  const [promoTaked, setPromoTaked] = useState(false);
  const [askedpromo, setAskedpromo] = useState(false);
  const [error, setError] = useState({
    name: false,
    phone: false,
    email: false
  });
  let mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  let telformat = /^(\+7|7|8)?[\s\-]?\(?[489][0-9]{2}\)?[\s\-]?[0-9]{3}[\s\-]?[0-9]{2}[\s\-]?[0-9]{2}$/

  const { errorOrder, opt, shops, productDots, showDatePicker, setShowDatePicker, produtosSelecionados, setProdutosSelecionados } = useContext(QtdContext);
  // console.log(produtosSelecionados)
  const shops2 = shops.filter(pr => pr.showed)
  const [newOrderID, setNewOrderID] = useState(null);

  function openModalDP(e) {
    e.preventDefault()
    setShowDatePicker(true)
  }
  const [newOrder, setNewOrder] = useState({
    ref: false,
    DiliveryCity: null,
    DiliveryType: null,
    bonus: 0, promocode: 0
  });
  function openModalDP(e) {
    e.preventDefault()
    setShowDatePicker(true)
  }

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

    if (produtosSelecionados.length == 0) {
      // console.log('eerf')

      // return (<>
      //   <Title text="Сначала добавьте товар в корзину!" />
      // </>)

      navigate('/');
      return
    }
  }, [])


  let cartItem = []
  const [summ, setSumm] = useState(0)
  let totalSumm = []
  const [processedCart, setProcessedCart] = useState([]);


  useEffect(() => {

    console.log(produtosSelecionados)
    if (!bonusTaked && !promoTaked) {
      for (let i = 0; i < produtosSelecionados.length; i++) {
        cartItem[i] = {
          id: produtosSelecionados[i].id,
          kategory: produtosSelecionados[i].kategory,
          shcode: produtosSelecionados[i].shcode,
          name: produtosSelecionados[i].name,
          photo: produtosSelecionados[i].photo,
          mnt: produtosSelecionados[i].mnt,
          price: produtosSelecionados[i].price,
          oldprice: produtosSelecionados[i].price,
          sale: 0,
          quantidade: produtosSelecionados[i].quantidade,
          fullquantity: Number(produtosSelecionados[i].fullquantity ? produtosSelecionados[i].fullquantity : produtosSelecionados[i].quantidade),
          sum: Number((Number(produtosSelecionados[i].fullquantity ? produtosSelecionados[i].fullquantity : produtosSelecionados[i].quantidade) * Number(produtosSelecionados[i].price)).toFixed(2)),
          tax: 'none',
          deliveryFromOtherShop: produtosSelecionados[i].deliveryFromOtherShop ? produtosSelecionados[i].deliveryFromOtherShop : false,
          fullHave: produtosSelecionados[i].fullHave ? produtosSelecionados[i].fullHave : false,
          shop_id: produtosSelecionados[i].fullHave ? produtosSelecionados[i].fullHave : null

        }
        console.log(cartItem[i])
        totalSumm = Number((Number(totalSumm) + Number(cartItem[i].sum)))
      }
    }
    if (bonusTaked) {
      let priceSale = 0
      for (let i = 0; i < produtosSelecionados.length; i++) {
        priceSale = Number(produtosSelecionados[i].quantidade) * Number(produtosSelecionados[i].price) + priceSale
      }
      for (let i = 0; i < produtosSelecionados.length; i++) {
        const sale = ((Number(produtosSelecionados[i].price) * ((Number(bonus / priceSale * 100)) / 100)).toFixed(2))

        cartItem[i] = {
          kategory: produtosSelecionados[i].kategory,

          shcode: produtosSelecionados[i].shcode,
          photo: produtosSelecionados[i].photo,
          id: produtosSelecionados[i].id,
          name: produtosSelecionados[i].name,
          sale: sale,
          mnt: produtosSelecionados[i].mnt,
          price: produtosSelecionados[i].price - sale,
          oldprice: produtosSelecionados[i].price,
          quantidade: produtosSelecionados[i].quantidade,
          sum: Number((Number(produtosSelecionados[i].fullquantity ? produtosSelecionados[i].fullquantity : produtosSelecionados[i].quantidade) * (Number(produtosSelecionados[i].price) - Number(sale))).toFixed(2)),
          tax: 'none',
          fullquantity: Number(produtosSelecionados[i].fullquantity ? produtosSelecionados[i].fullquantity : produtosSelecionados[i].quantidade),
          deliveryFromOtherShop: produtosSelecionados[i].deliveryFromOtherShop ? produtosSelecionados[i].deliveryFromOtherShop : false,
          fullHave: produtosSelecionados[i].fullHave ? produtosSelecionados[i].fullHave : false,
          shop_id: produtosSelecionados[i].fullHave ? produtosSelecionados[i].fullHave : null
        }
        console.log(cartItem[i])
        totalSumm = Number((Number(totalSumm) + Number(cartItem[i].sum)))
      }

      // console.log('bonus taked')
    }
    if (promoTaked) {
      for (let i = 0; i < produtosSelecionados.length; i++) {
        const sale = ((Number(produtosSelecionados[i].price) * ((Number(promo)) / 100)).toFixed(2))

        cartItem[i] = {
          kategory: produtosSelecionados[i].kategory,
          mnt: produtosSelecionados[i].mnt,

          shcode: produtosSelecionados[i].shcode,
          photo: produtosSelecionados[i].photo,
          id: produtosSelecionados[i].id,
          name: produtosSelecionados[i].name,
          sale: sale,
          price: produtosSelecionados[i].price - sale,
          oldprice: produtosSelecionados[i].price,
          quantidade: produtosSelecionados[i].quantidade,
          sum: Number((Number(produtosSelecionados[i].fullquantity ? produtosSelecionados[i].fullquantity : produtosSelecionados[i].quantidade) * (Number(produtosSelecionados[i].price) - Number(sale))).toFixed(2)),
          tax: 'none',
          fullquantity: Number(produtosSelecionados[i].fullquantity ? produtosSelecionados[i].fullquantity : produtosSelecionados[i].quantidade),
          deliveryFromOtherShop: produtosSelecionados[i].deliveryFromOtherShop ? produtosSelecionados[i].deliveryFromOtherShop : false,
          fullHave: produtosSelecionados[i].fullHave ? produtosSelecionados[i].fullHave : false,
          shop_id: produtosSelecionados[i].fullHave ? produtosSelecionados[i].fullHave : null
        }
        console.log(cartItem[i])
        totalSumm = Number((Number(totalSumm) + Number(cartItem[i].sum)))
      }
    }


    if (newOrder.DiliveryType == 'Самовывоз' && newOrder.shop_id) {

      const test = checkstore(cartItem, newOrder.shop_id)
      const totalQty = Number((test.reduce((sum, item) => sum + (Number(item.sum) || 0), 0)).toFixed(2));


      console.log('test', test)
      setSumm(totalQty)
      setProcessedCart(test)
    } else {
      const totalQty = Number((cartItem.reduce((sum, item) => sum + (Number(item.sum) || 0), 0)).toFixed(2));

      setSumm(totalQty)
      setProcessedCart(cartItem)
    }

  }, [produtosSelecionados, bonusTaked, promoTaked, bonus, promo, newOrder.shop_id])




  useEffect(() => {
    axios.post(`${BACKURL}/api/createordervisit`, newOrder)

  }, [newOrder])
  // console.log(cartItem, produtosSelecionados)

  const SendOrderOnline = async (e) => {
    try {
      // console.log(newOrder)
      e.preventDefault();



      let t = {
        name: false,
        phone: false,
        email: false,
        adressConfirm: false,
        DiliveryType: false,
        DiliveryCity: false,
        DiliveryAdress: false,
        DiliveryFlat: false,
        DiliveryHouse: false,
        selectedDate: false,
        selectedTime: false
      }
      if (!mailformat.test(newOrder.email)) {
        t.email = 'Адрес Электронной Почты неверный!'
        // setError({ ...error, email: 'Адрес Электронной Почты обязателен для заполнения!' });
      }

      if (!telformat.test(newOrder.phone)) {
        t.phone = 'Номер телефона должен быть формата +79872349999!'
        // setError({ ...error, phone: 'Номер телефона обязателен для заполнения!' });
      }
      if (!newOrder.email) {
        t.email = 'Адрес Электронной Почты обязателен для заполнения!'
        // setError({ ...error, email: 'Адрес Электронной Почты обязателен для заполнения!' });
      }
      if (!newOrder.phone) {
        t.phone = 'Номер телефона обязателен для заполнения!'
        // setError({ ...error, phone: 'Номер телефона обязателен для заполнения!' });
      }
      if (!newOrder.name) {
        t.name = 'Имя обязателен для заполнения!'
        // setError({ ...error, name: 'Имя обязателено для заполнения!' });
      }
      if (!newOrder.DiliveryType) {
        t.DiliveryType = 'Необходимо выбрать способ доставки!'
        // setError({ ...error, name: 'Имя обязателено для заполнения!' });
      }

      if (newOrder.DiliveryType != 'Самовывоз') {
        if (summ < 1500) {
          t.DiliveryCity = 'Доставка осуществляется от 1200р!'
        }
        // if (adressConfirm !== 'Подтверждено') {
        //   t.adressConfirm = 'Подтвердите адрес доставки!'

        // }
        // if (newOrder.DiliveryCity.toLowerCase() == "калининград") {

        // }
        if (!newOrder.DiliveryCity) {
          t.DiliveryCity = 'Необходимо выбрать город доставки!'
          // setError({ ...error, name: 'Имя обязателено для заполнения!' });
        }
        if (!newOrder.DiliveryAdress) {
          t.DiliveryAdress = 'Необходимо указать доставки!'
          // setError({ ...error, name: 'Имя обязателено для заполнения!' });
        }
        if (!newOrder.DiliveryFlat) {
          t.DiliveryFlat = 'Необходимо указать номер квартиры или офиса!'
          // setError({ ...error, name: 'Имя обязателено для заполнения!' });
        }
        if (!newOrder.DiliveryHouse) {
          t.DiliveryHouse = 'Необходимо указать номер дома или подьезда!'
          // DiliveryHouse({ ...error, name: 'Имя обязателено для заполнения!' });
        }
        if (!selectedDate) {
          t.selectedDate = 'Необходимо выбрать дату доставки!'
          // DiliveryHouse({ ...error, name: 'Имя обязателено для заполнения!' });
        }

        if (!selectedTime) {
          t.selectedTime = 'Необходимо выбрать время доставки/самовывоза!'
          // DiliveryHouse({ ...error, name: 'Имя обязателено для заполнения!' });
        }
      }


      setError(t)


      if (!t.name
        && !t.selectedDate
        && !t.email
        // && !t.adressConfirm
        && !t.phone
        && !t.DiliveryHouse
        && !t.DiliveryFlat
        && !t.DiliveryAdress
        && !t.DiliveryCity
        && !t.selectedTime
        && newOrder.DiliveryType
        && selectedDate
      ) {
        let formattedDate = 'Вторая/Последняя пятница месяца'


        if (selectedDate && selectedTime) {
          // console.log('tut3')
          const day = String(selectedDate.getDate()).padStart(2, '0'); // Получаем день месяца и добавляем ведущий ноль, если нужно
          const month = String(selectedDate.getMonth() + 1).padStart(2, '0'); // Получаем месяц (начиная с 0) и добавляем ведущий ноль, если нужно
          const year = selectedDate.getFullYear(); // Получаем год
          formattedDate = `${day}/${month}/${year}`;

        }

        setpaytaped(true)
        setLoading(true)

        const sendJson = processedCart.map(pr => {
          const { quantidade, ...rest } = pr; // Извлекаем `quantidade` и сохраняем остальные свойства
          return {
            ...rest,
            quantity: quantidade, // Добавляем новое свойство `quantity`
          };
        });
/////////////////////////////////
        const sendedOrder = await axios.post(`${BACKURL}/api/neworderreact`, {
          user: { ...newOrder, paytype: 'Оплата картой или СБП' },
          order: sendJson,
          selectedTime: selectedTime,
          selectedDate: formattedDate,
          promo: promoTaked ? promo : 0,
          bonus: bonusTaked ? bonus : 0
        })

console.log(summ, sendJson)

        let form = document.createElement('form');
        form.action = 'https://kotopes39.server.paykeeper.ru/create/';
        form.method = 'POST';
        form.hidden = true
        let s = 0
        s = `<input name='sum' value="${summ}" />`;
        form.innerHTML = `${s}` +
          `<input name='orderid' value='${sendedOrder.data}'/>` +
          `<input name='clientid' value='${newOrder.name}'/>` +
          `<input name='client_email' value='${newOrder.email}'/>` +
          `<input name='client_phone' value='${newOrder.phone}'/` +
          `<input name='service_name'  value='Оплата в магазине натуральных кормов КотоPеs' /> ` +
          `<input name='user_result_callback'  value='https://kotopes39.ru/success/:${sendedOrder.data}' />` +
          `<input name='cart'  value='${JSON.stringify(sendJson)}' />` +
          document.body.append(form);

        form.submit();

        setProdutosSelecionados([])
        localStorage.setItem("produtosSelecionados", JSON.stringify([]));
        setpaytaped(false)
        setLoading(false)


      }


    } catch (e) {
      setLoading(false)
      // console.log(e)
    }

  }

  const SendOrderOffline = async (e) => {
    try {
      // console.log(newOrder)
      e.preventDefault();



      let t = {
        name: false,
        phone: false,
        email: false,
        // adressConfirm: false,
        DiliveryType: false,
        DiliveryCity: false,
        DiliveryAdress: false,
        DiliveryFlat: false,
        DiliveryHouse: false,
        selectedDate: false,
        selectedTime: false
      }
      if (!mailformat.test(newOrder.email)) {
        t.email = 'Адрес Электронной Почты неверный!'
      }

      if (!telformat.test(newOrder.phone)) {
        t.phone = 'Номер телефона должен быть формата +79872349999!'
      }
      if (!newOrder.email) {
        t.email = 'Адрес Электронной Почты обязателен для заполнения!'
      }
      if (!newOrder.phone) {
        t.phone = 'Номер телефона обязателен для заполнения!'
      }
      if (!newOrder.name) {
        t.name = 'Имя обязателен для заполнения!'
      }
      if (!newOrder.DiliveryType) {
        t.DiliveryType = 'Необходимо выбрать способ доставки!'
      }

      if (newOrder.DiliveryType != 'Самовывоз') {
        if (summ < 1500) {
          t.DiliveryCity = 'Доставка осуществляется от 1200р!'
        }
        // if (adressConfirm !== 'Подтверждено') {
        //   t.adressConfirm = 'Подтвердите адрес доставки!'

        // }
        if (!newOrder.DiliveryCity) {
          t.DiliveryCity = 'Необходимо выбрать город доставки!'
        }
        if (!newOrder.DiliveryAdress) {
          t.DiliveryAdress = 'Необходимо указать доставки!'
        }
        if (!newOrder.DiliveryFlat) {
          t.DiliveryFlat = 'Необходимо указать номер квартиры или офиса!'
        }
        if (!newOrder.DiliveryHouse) {
          t.DiliveryHouse = 'Необходимо указать номер дома или подьезда!'
        }
        if (!selectedDate) {
          t.selectedDate = 'Необходимо выбрать дату доставки/самовывоза!'
        }
        if (!selectedTime) {
          t.selectedTime = 'Необходимо выбрать время доставки/самовывоза!'
        }
      }



      setError(t)
      let fal = true
      if (!t.name
        && fal
        // && !t.adressConfirm
        && !t.email
        && !t.phone
        && newOrder.name
        && newOrder.phone
        && newOrder.email
        && newOrder.DiliveryType) {
        setLoading(true)
        let formattedDate = 'Вторая/Последняя пятница месяца'
        if (selectedDate && selectedTime) {
          const day = String(selectedDate.getDate()).padStart(2, '0'); // Получаем день месяца и добавляем ведущий ноль, если нужно
          const month = String(selectedDate.getMonth() + 1).padStart(2, '0'); // Получаем месяц (начиная с 0) и добавляем ведущий ноль, если нужно
          const year = selectedDate.getFullYear(); // Получаем год
          formattedDate = `${day}/${month}/${year}`;

        }

        const sendJson = processedCart.map(pr => {
          const { quantidade, ...rest } = pr; // Извлекаем `quantidade` и сохраняем остальные свойства
          return {
            ...rest,
            quantity: quantidade, // Добавляем новое свойство `quantity`
          };
        });
        console.log(sendJson)
        const sendedOrder = await axios.post(`${BACKURL}/api/neworderreact`, {
          user: { ...newOrder, quantity: newOrder.quantidade, paytype: 'Наличные/Карта при получении' },
          order: sendJson,
          promo: promoTaked ? promo : 0,
          bonus: bonusTaked ? bonus : 0
        })
        setProdutosSelecionados([])
        localStorage.setItem("produtosSelecionados", JSON.stringify([]));
        setNewOrderID(sendedOrder.data)
        setLoading(false)


        setTimeout(() => {
          setNewOrderID('')
          navigate('/')
          setNewOrderID('')
        }, 60000)
      }
    } catch (e) {
      setLoading(false)
      // console.log(e)
    }

  }
  const askPromo = async (e) => {
    try {
      let t1 = false

      if (!(newOrder.promocodeName)) {
        t1 = 'Введите промокод!'
      }

      setErrorPromo(t1)

      if (newOrder.promocodeName && !t1.promocodeName) {
        const userBonus = await axios.post(`${BACKURL}/api/promocodes/check`, { code: newOrder.promocodeName })
        if (userBonus) {
          setPromo(userBonus.data)
          setAskedpromo(true)
          // setAsked(false)
          setBonusTaked(false)
          setPromoTaked(true)
          setBonus(0)
          // setBonus(0)
        }

      }
    } catch (e) {
      // console.log(e)
      setErrorPromo(`${e.response?.data?.message}!`)

    }
  }



  const askBonus = async (e) => {
    e.preventDefault();

    let t1 = {

      email: false,

    }
    if (!mailformat.test(newOrder.email)) {
      t1.email = 'Адрес Электронной Почты неверный!'
    }

    if (!newOrder.email) {
      t1.email = 'Введите Адрес Электронной Почты выше!'
    }

    setError1(t1)
    if (newOrder.email && !t1.email) {
      const userBonus = await axios.post(`${BACKURL}/api/userbonus`, { mail: newOrder.email })
      setAsked(true)
      let bonus = 0
      if (Number(userBonus.data) > summ / 4) {
        bonus = (summ / 4).toFixed(0)
      } else {
        bonus = userBonus.data
      }
      setTimeBonus(bonus)
      setBonusALL(Number(userBonus.data))

    }
  }

  const askRef = async (e) => {
    e.preventDefault();
    let t1 = {
      email: false,
      ref: false,
    }

    if (!mailformat.test(newOrder.email)) {
      t1.email = 'Адрес Электронной Почты неверный!'
    }


    if (!newOrder.email) {
      t1.email = 'Введите Адрес Электронной Почты выше!'
    }
    if (!ref) {
      t1.ref = 'Введите Адрес Электронной Рефера выше!'
    }
    if (newOrder.email == ref) {
      t1.ref = 'Вы не можете указать свой адрес электронной почты!'
    }
    if (!mailformat.test(ref)) {
      t1.ref = 'Адрес Электронной Почты неверный!'
    }
    setError1(t1)
    if (!t1.email && !t1.ref && newOrder.email && ref) {
      try {
        setLoading(true)
        const userRef = await axios.post(`${BACKURL}/api/utbteam/users/checkref`, { mail: newOrder.email, ref: ref })

        setRef(null)


        const reffer = userRef.data

        if (!reffer) {
          t1.ref = 'Рефера нет в системе!'
        }

        setNewOrder({ ...newOrder, ref: reffer })
        setError1([])
        setLoading(false)
      } catch (e) {
        setLoading(false)
        setError1({ ref: e.response?.data?.message })
      }


    }


  }

  const takeBonus = async (e) => {
    e.preventDefault();
    // setNewOrder({ ...newOrder, bonus: bonus })
    // setNewOrder({ ...newOrder, promocode: 0 })
    // console.log(newOrder)
    setBonus(timebonus)

    setAskedpromo(false)
    setAsked(false)
    setBonusTaked(true)
    setPromoTaked(false)
    setPromo(0)

  }

  const checkstore = (cart, selectedStoreId1) => {
    try {
      const report = [];
      const selectedStoreId = selectedStoreId1 ? selectedStoreId1 : Math.floor(Math.random() * shops2.length) + 1;

      cart.forEach((item) => {
        const { id, shcode, quantidade, sum } = item;

        const selectedStore = shops2.find(store => store.id === selectedStoreId);

        if (!selectedStore) {
          throw new Error(`Выбранный магазин с ID ${selectedStoreId} не найден.`);
        }

        const storeItem = productDots.find(
          prod => prod.prod_id === id && prod.prod_sh === shcode && prod.shop_id === selectedStoreId
        );

        const storeRemaining = storeItem ? storeItem.scladqty : 0; // Сколько товара есть в выбранном магазине

        if (storeRemaining >= quantidade) {
          report.push({
            ...item,
            // sum: Number(storeRemaining) *( Number(item.price)-Number(item.sale)),

            // quantidade,
            sum: (Number(quantidade) * (Number(item.price))).toFixed(2),

            fullquantity: quantidade,
            shop_id: selectedStoreId,
            fullHave: true,
            deficit: 0
          });
        } else {
          const deficit = quantidade - storeRemaining; // Сколько не хватает

          report.push({
            ...item,
            sum: (Number(storeRemaining) * (Number(item.price))).toFixed(2),
            fullquantity: storeRemaining,
            shop_id: selectedStoreId,
            fullHave: false,
            deficit
          });
        }
      });

      // setNewOrder({ ...newOrder, shop_id: selectedStoreId });

      const tomorow = report.find(pr => pr.deficit > 0);

      if (newOrder.DiliveryType === 'Самовывоз') {
        settomorrow(tomorow ? 2 : 0);
      }
      if (['Доставка по Калининграду', 'Доставка по области'].includes(newOrder.DiliveryType)) {
        settomorrow(tomorow ? 2 : 1);
      }

      return report;
    } catch (e) {
      console.error(e);
    }
  };


  const sortOrderByShops = async (cart, selectedStoreId1) => {
    try {
      const report = [];
      const selectedStoreId = selectedStoreId1 ? selectedStoreId1 : Math.floor(Math.random() * shops2.length) + 1;
      cart.forEach((rr) => {

        const { id: id, shcode: shcode, quantidade: quantidade } = rr;

        const selectedStore = shops2.find(store => store.id === selectedStoreId);

        if (!selectedStore) {
          throw Error(`Выбранный магазин с ID ${selectedStoreId} не найден.`)


        }

        const storeItem = productDots.find(
          item => item.prod_id == id && item.prod_sh == shcode && item.shop_id == selectedStoreId
        );
        const storeRemaining = storeItem ? storeItem.scladqty : 0;

        if (storeRemaining >= quantidade) {
          report.push(
            { ...rr, shop_id: selectedStoreId, fullquantity: quantidade, deliveryFromOtherShop: false, fullHave: true }
          );
          return;
        }

        let deficit = quantidade - storeRemaining;

        let foundInOtherStores = false;
        let otherStoresReport = [];
        let availebelOnThisShop = 0
        shops2.forEach((store) => {

          if (store.id === selectedStoreId) return; // Пропускаем выбранный магазин

          const otherStoreItem = productDots.find(
            item => item.prod_id === id && item.prod_sh === shcode && item.shop_id === store.id
          );

          if (otherStoreItem && otherStoreItem.scladqty > 0) {
            const availableFromOtherStore = Math.min(deficit, otherStoreItem.scladqty);
            deficit -= availableFromOtherStore;
            foundInOtherStores = true;
            otherStoresReport.push(
              { shop_id: store.id, quantidade: availableFromOtherStore }
            );
          }
        });

        if (deficit === 0) {

          report.push(
            { ...rr, quantidade: storeRemaining, fullquantity: quantidade, shop_id: selectedStoreId, deliveryFromOtherShop: true, otherShop: otherStoresReport, fullHave: true }
          );
        } else {

          report.push(
            { ...rr, quantidade: storeRemaining, fullquantity: quantidade, shop_id: selectedStoreId, deliveryFromOtherShop: true, otherShop: otherStoresReport, fullHave: false }

          );
        }
      });
      setNewOrder({ ...newOrder, shop_id: selectedStoreId });
      const tomorow = report.find(pr => pr.deliveryFromOtherShop)
      if (newOrder.DiliveryType == 'Самовывоз') {
        if (tomorow) {
          settomorrow(2)
        } else {
          settomorrow(0)
        }
      }
      if (newOrder.DiliveryType == 'Доставка по Калининграду' || newOrder.DiliveryType == 'Доставка по области') {
        if (tomorow) {
          settomorrow(2)
        } else {
          settomorrow(1)
        }
      }

      return report;
    } catch (e) {
      // console.log(e)
    }
  }

  async function getCoordinatesAdres(address) {
    const baseUrl = "https://nominatim.openstreetmap.org/search";
    const url = `${baseUrl}?q=${encodeURIComponent(address)}&format=json&addressdetails=1`;

    try {
      const response = await axios.get(url, {
        headers: {
          headers: { 'User-Agent': 'kotopes/3.0 (kotopes.sale@gmail.com)' },
          timeout: 10000
        }
      });

      const data = response.data;
      if (data.length === 0) {
        throw new Error("Не удалось найти координаты по указанному адресу.");
      }
      return {
        lat: data[0].lat,
        lon: data[0].lon
      };
    } catch (error) {
      console.error("Ошибка:", error.message);
      return false;
    }
  }
  async function haversineDistance(point1, point2) {
    const toRadians = (degrees) => degrees * (Math.PI / 180);

    const R = 6371; // Радиус Земли в километрах

    const lat1 = parseFloat(point1.lat);
    const lon1 = parseFloat(point1.lon);
    const lat2 = parseFloat(point2.lat);
    const lon2 = parseFloat(point2.lon);

    const dLat = toRadians(lat2 - lat1);
    const dLon = toRadians(lon2 - lon1);

    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(toRadians(lat1)) * Math.cos(toRadians(lat2)) *
      Math.sin(dLon / 2) * Math.sin(dLon / 2);

    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    return R * c; // Расстояние в километрах
  }

  const Sovetskii = { id: 1, name: 'Калининград Советский проспект 204', coords: { lat: '54.7468851', lon: '20.46281956187693' } }
  const Koshevogo = { id: 2, name: 'Калининград Кошевого 15', coords: { lat: '54.674676149999996', lon: '20.51219725' } }

  async function haversinePreoritetShop(shops, adress, addressname) {
    // console.log(shops, adress, addressname)
    const distance = await haversineDistance(shops[0].coords, adress);
    const distance2 = await haversineDistance(shops[1].coords, adress);
    if (distance > distance2) {
      // console.log(`${shops[1].name} - ${addressname} ${distance2.toFixed(2)} км`)
      // console.log(`${shops[0].name} - ${addressname} ${distance.toFixed(2)} км`)
      // console.log(`Заказ Собираем на ${shops[1].name}`)
      return shops[1].id
    } else {
      // console.log(`${shops[0].name} - ${addressname} ${distance.toFixed(2)} км`)
      // console.log(`${shops[1].name} - ${addressname} ${distance2.toFixed(2)} км`)
      // console.log(`Заказ Собираем на ${shops[0].name}`)
      return shops[0].id
    }


  }
  async function AdressConfirm() {
    if (newOrder.DiliveryCity && newOrder.DiliveryAdress && newOrder.DiliveryHouse) {
      setLoading(true)

      const adress = `${newOrder.DiliveryCity} ${newOrder.DiliveryAdress} ${newOrder.DiliveryHouse}`

      const answ = await getCoordinatesAdres(adress)
      if (answ) {
        const WhereDeclay = await haversinePreoritetShop([Sovetskii, Koshevogo], answ, adress)
        const test = await sortOrderByShops(processedCart, WhereDeclay)
        setProdutosSelecionados(test)
        if (test) {
          setAdressConfirm('Подтверждено')
        }
      } else {
        const test1 = await sortOrderByShops(processedCart, null)
        // console.log(test1)

        setProdutosSelecionados(test1)
        setAdressConfirm('Адрес неверный')

      }

      setLoading(false)
    }
  }

  // async function setShopDeclay(id) {
  //   try {
  //     const test = await checkstore(processedCart, id)
  //     // console.log(test)
  //     // processedCart = test
  //     ///////////////////////////////////////////////////////////////
  //     // summ = 0
  //     // if (!bonusTaked && !promoTaked) {
  //     //   for (let i = 0; i < processedCart[i].length; i++) {
  //     //     processedCart[i] = {
  //     //       ...processedCart[i],
  //     //       sale: 0,
  //     //       sum: Number(processedCart[i].fullquantity) * Number(processedCart[i].price),
  //     //     }
  //     //     summ = summ + processedCart[i].sum
  //     //   }
  //     // }
  //     // if (bonusTaked) {
  //     //   let priceSale = 0
  //     //   for (let i = 0; i < processedCart[i].length; i++) {
  //     //     priceSale = Number(processedCart[i].fullquantity) * Number(processedCart[i].price) + priceSale
  //     //   }
  //     //   for (let i = 0; i < processedCart.length; i++) {
  //     //     const sale = ((Number(processedCart[i].price) * ((Number(bonus / priceSale * 100)) / 100)).toFixed(2))

  //     //     processedCart[i] = {
  //     //       ...processedCart[i],
  //     //       sale: sale,
  //     //       price: processedCart[i].price - sale,
  //     //       sum: Number(processedCart[i].fullquantity) * (Number(processedCart[i].price) - Number(sale)),
  //     //     }
  //     //     summ = Number((summ + processedCart[i].sum).toFixed(2))
  //     //   }

  //     //   // console.log('bonus taked')
  //     // }
  //     // if (promoTaked) {
  //     //   for (let i = 0; i < processedCart[i].length; i++) {
  //     //     const sale = ((Number(processedCart[i].price) * ((Number(promo)) / 100)).toFixed(2))
  //     //     processedCart[i] = {
  //     //       ...processedCart[i],
  //     //       sale: sale,
  //     //       price: processedCart[i].price - sale,
  //     //       sum: Number(processedCart[i].fullquantity) * (Number(processedCart[i].price) - Number(sale)),
  //     //     }
  //     //     summ = Number((summ + processedCart[i].sum).toFixed(2))
  //     //   }
  //     // }
  //     // console.log(processedCart)
  //     // setProcessedCart(test)
  //     // setProdutosSelecionados(test)
  //     setPromoTaked(true)

  //     // setPromo(10)


  //     // if (test) {
  //     //   setAdressConfirm('Подтверждено')
  //     // }

  //   } catch (e) {
  //     setLoading(false)
  //   }
  // }
  // useEffect(() => {
  //   if (newOrder.DiliveryType == 'Самовывоз' && newOrder.shop_id) {
  //     setShopDeclay(newOrder.shop_id)
  //   }
  // }, [processedCart])
  // useEffect(() => {
  //   if (adressConfirm == 'Подтверждено') {
  //     setAdressConfirm('Не подтверждено')
  //   }
  // }, [newOrder.DiliveryCity, newOrder.DiliveryAdress, newOrder.DiliveryHouse])
  useEffect(() => {
    if (minBuy > summ) {
      setBonus(0)
      setPromo(10)
      setBonusTaked(false)
      setPromoTaked(true)
      setNewOrder({ ...newOrder, DiliveryType: 'Самовывоз' });;
    }
  }, [promo, bonus])

  console.log(produtosSelecionados, processedCart)
  return (
    <>

      <div className={style.border}>
        {!newOrderID && (
          <>

            <br />



            <br />
            <div>
              <h3 style={{ padding: '10px', borderTop: '1px solid black', borderBottom: '1px solid black', textAlign: 'center', margin: '10px' }}>Контактная информация:</h3>
              <div style={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'row', margin: '10px', alignItems: 'center', justifyContent: 'center' }}>
                <div className={style.promodiv} style={{ margin: '10px' }} >
                  <label for="email">Электронная почта:</label>
                  <input value={newOrder.email} placeholder="Электронная почта" style={{ width: '200px', textAlign: 'center' }} type="email" id="email" name="email" onChange={(event) => {
                    setNewOrder({ ...newOrder, email: event.target.value.toLowerCase() });
                    setBonus(0)
                    setBonusTaked(false)
                  }}
                  />
                  {error.email && (
                    <div className={style.promodiv} style={{ margin: '10px', color: 'red', width: '200px' }} >
                      {error.email}</div>
                  )}
                  {error1.email && (

                    <div className={style.promodiv} style={{ margin: '10px', color: 'red', width: '200px' }} >
                      {error1.email}</div>
                  )}
                </div>
                <div className={style.promodiv} style={{ margin: '10px' }} >
                  <label for="name">Ваше Имя</label>
                  <input value={newOrder.name} placeholder="Ваше Имя" style={{ width: '200px', textAlign: 'center' }} type="name" id="name" name="name" onChange={(event) => {
                    setNewOrder({ ...newOrder, name: event.target.value });

                  }}
                  />
                  {error.name && (
                    <div className={style.promodiv} style={{ margin: '10px', color: 'red', width: '200px' }} >
                      {error.name}</div>
                  )}
                </div>
                <div className={style.promodiv} style={{ margin: '10px' }}>
                  <label for="phone">Номер Телефона</label>
                  <input on
                    value={newOrder.phone} placeholder="Номер Телефона" style={{ width: '200px', textAlign: 'center' }} type="phone" id="phone" phone="phone" onChange={(event) => {
                      setNewOrder({ ...newOrder, phone: event.target.value.toLowerCase() });

                    }}
                  />
                  {error.phone && (
                    <div className={style.promodiv} style={{ margin: '10px', color: 'red', width: '200px' }} >
                      {error.phone}</div>
                  )}
                </div>
              </div>
              <h3 style={{ textAlign: 'center', margin: '10px' }}>Способ получения:</h3>
              <h5 style={{ textAlign: 'center', margin: '10px' }}>При получении заказа самовывозом дается 10% скидки</h5>
              <h5 style={{ textAlign: 'center', margin: '10px' }}>Бесплатная доставка доступна при заказе от 1500р</h5>
              <div style={{ display: 'flex', borderBottom: '2px gray solid', borderTop: '2px gray solid', flexWrap: 'wrap', flexDirection: 'row', margin: '10px', alignItems: 'center', justifyContent: 'center' }}>

                <div
                  onClick={() => {
                    if (minBuy <= summ) {
                      setPromo(0)

                      setPromoTaked(false)
                      setNewOrder({ ...newOrder, DiliveryType: 'Доставка по Калининграду', shop_id: null });
                    }

                  }}
                  style={{
                    opacity: (minBuy > summ) ? '0.5' : '1',
                    width: '130px', cursor: 'pointer', height: '100px', borderRadius: '15px', fontSize: '14px', display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '10px', margin: '10px',
                    background: newOrder.DiliveryType === 'Доставка по Калининграду' ? 'var(--primary-color)' : 'lightgray'
                  }}>
                  Доставка по Калининграду
                </div>
                <div
                  onClick={() => {
                    setBonus(0)
                    setPromo(10)
                    setBonusTaked(false)
                    setPromoTaked(true)
                    setNewOrder({ ...newOrder, DiliveryType: 'Самовывоз' });

                  }}
                  style={{
                    width: '130px', cursor: 'pointer', height: '100px', borderRadius: '15px', fontSize: '14px', display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '10px', margin: '10px',
                    background: newOrder.DiliveryType === 'Самовывоз' ? 'var(--primary-color)' : 'lightgray'
                  }}>
                  Самовывоз
                </div>
                <div
                  onClick={() => {
                    if (minBuy <= summ) {
                      setPromo(0)
                      setPromoTaked(false)
                      setNewOrder({ ...newOrder, DiliveryType: 'Доставка по области', shop_id: null });
                    }
                  }}
                  style={{
                    opacity: (minBuy > summ) ? '0.5' : '1',
                    width: '130px', cursor: 'pointer', height: '100px', borderRadius: '15px', fontSize: '14px', display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '10px', margin: '10px',
                    background: newOrder.DiliveryType === 'Доставка по области' ? 'var(--primary-color)' : 'lightgray'
                  }}>
                  Доставка по области
                </div>
              </div>
              {(newOrder.DiliveryType == 'Доставка по Калининграду' || newOrder.DiliveryType == 'Доставка по области') && (
                <>
                  <div className={style.promodiv} style={{ margin: '10px' }} >

                    <h3 style={{ textAlign: 'center', margin: '10px' }}>{newOrder.DiliveryType}:</h3>
                  </div>
                  <div style={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'row', margin: '10px', alignItems: 'center', justifyContent: 'center' }}>

                    <div className={style.promodiv} style={{ margin: '10px' }} >
                      <label for="DiliveryCity">Город</label>
                      <input value={newOrder.DiliveryCity} placeholder="Город" style={{ width: '200px', textAlign: 'center' }} type="name" id="name" name="name" onChange={async (event) => {
                        setNewOrder({ ...newOrder, DiliveryCity: event.target.value });
                        // const adress = `${event.target.value} ${newOrder.DiliveryAdress} ${newOrder.DiliveryHouse}`

                        // const answ = await getCoordinatesAdres(adress)
                        // console.log(answ)
                      }}
                      />
                      {error.DiliveryCity && (
                        <div className={style.promodiv} style={{ margin: '10px', color: 'red', width: '200px' }} >{error.DiliveryCity}</div>
                      )}
                    </div>

                    <div className={style.promodiv} style={{ margin: '10px' }} >
                      <label for="DiliveryAdress">Улица/Проспект</label>
                      <input value={newOrder.DiliveryAdress} placeholder="Улица/Проспект" style={{ width: '200px', textAlign: 'center' }} type="name" id="name" name="name" onChange={async (event) => {
                        setNewOrder({ ...newOrder, DiliveryAdress: event.target.value });
                        //   const adress = `${newOrder.DiliveryCity} ${event.target.value} ${newOrder.DiliveryHouse}`
                        //   const answ = await getCoordinatesAdres(adress)
                        //   console.log(answ)
                      }}
                      />
                      {error.DiliveryAdress && (
                        <p style={{ color: "red" }}>{error.DiliveryAdress}</p>
                      )}
                    </div>
                  </div>
                  <div style={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'row', margin: '10px', alignItems: 'center', justifyContent: 'center' }}>

                    <div className={style.promodiv} style={{ margin: '10px' }} >
                      <label for="DiliveryHouse">Дом/Подьезд</label>
                      <input value={newOrder.DiliveryHouse} placeholder="Дом/Подьезд" style={{ width: '200px', textAlign: 'center' }} type="name" id="name" name="name" onChange={async (event) => {
                        setNewOrder({ ...newOrder, DiliveryHouse: event.target.value });
                        // const adress = `${newOrder.DiliveryCity} ${newOrder.DiliveryAdress} ${event.target.value}`
                        // const answ = await getCoordinatesAdres(adress)
                        // console.log(answ)
                      }}
                      />
                      {error.DiliveryHouse && (
                        <div className={style.promodiv} style={{ margin: '10px', color: 'red', width: '200px' }} >{error.DiliveryHouse}</div>
                      )}
                    </div>
                    <div className={style.promodiv} style={{ margin: '10px' }} >
                      <label for="DiliveryFlat">Квартира/Офис</label>
                      <input value={newOrder.DiliveryFlat} placeholder="Квартира/Офис" style={{ width: '200px', textAlign: 'center' }} type="name" id="name" name="name" onChange={(event) => {
                        setNewOrder({ ...newOrder, DiliveryFlat: event.target.value });

                      }}
                      />
                      {error.DiliveryFlat && (
                        <div className={style.promodiv} style={{ margin: '10px', color: 'red', width: '200px' }} >{error.DiliveryFlat}</div>
                      )}
                    </div>
                  </div>
                  <div className={style.promodiv} style={{ margin: '10px' }} >
                    {/* 
                    {adressConfirm == 'Не подтверждено' && (<>
                      <Button text='Продтвердить адрес'
                        onClick={AdressConfirm}
                      />
                      {error.adressConfirm && (
                        <div className={style.promodiv} style={{ margin: '10px', color: 'red', width: '200px' }} >
                          {error.adressConfirm}</div>
                      )}
                    </>

                    )}
                    {adressConfirm == 'Подтверждено' && (
                      <Button text='Адрес подтвержден'
                        background='#20b6e8'
                      // onClick={AdressConfirm}
                      />
                    )}
                    {adressConfirm == 'Адрес неверный' && (<>
                      <h5>Адрес неверный</h5>
                      <Button text='Все равно доставить сюда'
                        // color={'red'}

                        onClick={() => {
                          // setNewOrder({...newOrder,shop_id:1})
                          setAdressConfirm('Подтверждено')
                        }}

                      />
                      {error.adressConfirm && (
                        <div className={style.promodiv} style={{ margin: '10px', color: 'red', width: '200px' }} >
                          {error.adressConfirm}</div>
                      )}

                    </>
                    )} */}
                  </div>


                </>
              )}

              {newOrder.DiliveryType == 'Самовывоз' && (
                <>
                  <h3 style={{ textAlign: 'center', padding: '10px', margin: '10px' }}>{newOrder.DiliveryType}:</h3>
                  <h4 style={{ textAlign: 'center' }}>Выберите адрес магазина, где заберете заказ</h4>
                  <div style={{ display: 'flex', borderBottom: '2px gray solid', borderTop: '2px gray solid', flexWrap: 'wrap', flexDirection: 'row', margin: '10px', alignItems: 'center', justifyContent: 'center' }}>

                    {shops2.map(sh => {
                      return <div
                        onClick={() => {
                          setPromo(10)
                          setPromoTaked(true)
                          setNewOrder({ ...newOrder, shop_id: sh.id })
                          // setShopDeclay(sh.id)
                        }}
                        style={{
                          width: '130px', cursor: 'pointer', height: '100px', borderRadius: '15px', fontSize: '14px', display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '10px', margin: '10px',
                          background: newOrder.shop_id === sh.id ? 'var(--primary-color)' : 'lightgray'
                        }}>
                        {sh.adress}
                      </div>

                    })}
                  </div>
                </>

              )}
              {newOrder.DiliveryType !== 'Самовывоз' && (<>
                {/* {(adressConfirm == 'Подтверждено' || newOrder.shop_id) && (<> */}
                <h3 style={{ textAlign: 'center', padding: '10px', margin: '10px' }}>Дата и время доставки:</h3>
                <div style={{ display: 'flex', borderBottom: '2px gray solid', borderTop: '2px gray solid', flexWrap: 'wrap', flexDirection: 'row', margin: '10px', alignItems: 'center', justifyContent: 'center', padding: '10px' }}>
                  {/* {newOrder.DiliveryType == 'Доставка по Калининграду' && ( */}
                  {newOrder.DiliveryType == 'Доставка по области' && (
                    <div style={{ width: '200px', textAlign: 'center' }}>
                      <label for="selectedDate">Дата доставки:</label>
                      <select type="text" placeholder="Дата доставки" style={{ width: '200px', textAlign: 'center', margin: '10%' }} value={newOrder.selectedDate} id="selectedDate" name="selectedDate" onChange={(event) => {
                        setSelectedDate(new Date(event.target.value));
                      }} >
                        <option value=''>Выберите дату доставки </option>

                        {/* <option value='12/13/24'>13/12/24</option> */}
                        <option value='12/27/24'>27/12/24</option>
                        <option value='12/27/24'>10/01/25</option>
                        <option value='12/27/24'>24/01/25</option>
                      </select>
                      {error.selectedDate && (
                        <p style={{ color: "red" }}>{error.selectedDate}</p>
                      )}
                    </div>
                  )}
                  {newOrder.DiliveryType == 'Доставка по Калининграду' && (
                    <>
                      <div className={style.addressInput} >

                        {/* <label for="selectedDate">Дата доставки:</label> */}

                        {/* <h5>{selectedDate ? `Выбранная дата доставки: ${selectedDate.toLocaleDateString()}` : 'Выберите дату доставки'}</h5> */}
                        <Button text={selectedDate ? `Выбранная дата доставки: ${selectedDate.toLocaleDateString()}` : 'Выберите дату доставки'} onClick={openModalDP}></Button>
                        {error.selectedDate && (
                          <p style={{ color: "red" }}>{error.selectedDate}</p>
                        )}
                      </div>
                      <div className={style.adressInput} >
                        {/* <label for="selectedTime">Время доставки:</label> */}
                        <select style={{ width: '200px', textAlign: 'center', margin: '10%' }} placeholder="Выберите время доставки" onChange={(event) => setSelectedTime(event.target.value)}>
                          <option value=''>Выберите время доставки </option>
                          {newOrder.DiliveryType == 'Доставка по Калининграду' && (<>
                            <option value='с 12:00 до 16:00'>с 12:00 до 16:00</option>
                            <option value='с 16:00 до 20:00'>с 16:00 до 20:00</option>
                          </>

                          )}
                          {newOrder.DiliveryType == 'Доставка по области' && (<>
                            <option value='с 12:00 до 14:00'>с 12:00 до 14:00</option>
                            <option value='с 14:00 до 16:00'>с 14:00 до 16:00</option>
                          </>

                          )}
                          {newOrder.DiliveryType == 'Самовывоз' && (<>
                            <option value='с 11:00 до 13:00'>с 11:00 до 13:00</option>
                            <option value='с 13:00 до 15:00'>с 13:00 до 15:00</option>
                            <option value='с 15:00 до 17:00'>с 15:00 до 17:00</option>
                            <option value='с 17:00 до 20:00'>с 17:00 до 20:00</option>
                          </>

                          )}
                          {/* <option value='с 17:00 до 21:00'>с 17:00 до 21:00</option> */}
                        </select>
                        {error.selectedTime && (
                          <p style={{ color: "red" }}>{error.selectedTime}</p>
                        )}
                      </div>
                    </>
                  )}
                  {/* )} */}

                </div>
              </>)}
              {/* </> */}
              {/* )} */}
              <h3 style={{ textAlign: 'center', margin: '10px' }}>Ваш Заказ:</h3>
              <div style={{ display: 'flex', flexDirection: 'column', margin: '10px', alignItems: 'center', justifyContent: 'center', borderTop: '2px gray solid', borderBottom: '2px gray solid' }}>

                {(processedCart).map(({ photo, name, quantidade, fullquantity, id, kategory, deficit, fullHave, mnt, sale, sum, shcode }, index) => {
                  let maximum2 = 0
                  for (let i = 0; i < shops2.length; i++) {
                    const scladqty = productDots.find(pd => shops2[i].showed && pd.prod_sh == shcode && pd.prod_id == id && shops2[i].id == pd.shop_id)
                    if (scladqty) {
                      maximum2 = maximum2 + scladqty.scladqty
                      // console.log(shops2[i], scladqty)
                    }
                  }
                  // console.log(id)
                  return <>
                    <div style={{ width: '100%', textAlign: 'center', display: 'flex', flexDirection: 'row', margin: '10px', alignItems: 'center', justifyContent: 'space-evenly' }}>
                      <div style={{ width: '20%', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '10px' }}>
                        <img style={{ width: '100px', borderRadius: '15px' }} src={photo[0]} alt={shcode} />
                      </div>
                      <div style={{ width: '80%', flexDirection: 'column', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '10px' }}>
                        <div style={{ width: '100%', fontSize: '10px', display: 'flex', justifyContent: 'start', alignItems: 'center', padding: '10px' }}>
                          <b>{name}</b>
                        </div>
                        <div style={{ width: '100%', fontSize: '10px', display: 'flex', justifyContent: 'start', alignItems: 'center', padding: '10px' }}>
                          {!fullHave && newOrder.DiliveryType == 'Самовывоз' && newOrder.shop_id && deficit > 0 && (
                            <b style={{ fontSize: '10px', color: 'red' }}>На данном магазине не хватает {deficit}{mnt}</b>
                          )}
                        </div>
                        <div style={{ width: '100%', flexDirection: 'row', display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', padding: '10px' }}>

                          <div style={{ width: '50%', display: 'flex', justifyContent: 'start', alignItems: 'center', paddingTop: '10px', paddingBottom: '10px' }}>
                            <b>{(!fullHave && newOrder.DiliveryType == 'Самовывоз' && newOrder.shop_id) ? fullquantity : quantidade}{mnt}



                              {sale > 0 && (
                                <b style={{ fontSize: '10px' }}>(Скидка: -{sale}Руб/шт)</b>

                              )}</b>


                          </div>


                          <div style={{ width: '50%', display: 'flex', justifyContent: 'end', alignItems: 'center', padding: '10px' }}>
                            <b>{sum}</b>Руб
                          </div>
                        </div>
                      </div>


                    </div>
                  </>

                })}

                <p style={{ marginBottom: '10px', fontSize: '25px' }}> Итого: <b>{summ}</b>Руб</p>
              </div>
              <h3 style={{ textAlign: 'center', margin: '10px' }}>Бонусы и Промокоды:</h3>
              <div style={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'row', margin: '10px', justifyContent: 'space-evenly', borderBottom: '2px gray solid' }}>
                {/* <div style={{ width: '250px', display: 'flex', flexDirection: 'column', margin: '10px', alignItems: 'center', justifyContent: 'start' }}> */}
                {!newOrder.ref && (<>
                  <div style={{ width: '250px', display: 'flex', flexDirection: 'column', margin: '10px', alignItems: 'center', justifyContent: 'start' }}>
                    <h4  >Электронная Почта Рефера:</h4>
                    <div className={style.promodiv} style={{ margin: '10px' }} >
                      <input value={ref} placeholder="Реферер" style={{ width: '200px', textAlign: 'center' }} type="email" id="email" name="email" onChange={(event) => {
                        setRef(event.target.value.toLowerCase());

                        // console.log(ref)
                        // setBonus(0)
                        // setBonusTaked(false)
                      }}
                      />

                      {error1.ref && (
                        <div className={style.promodiv} style={{ margin: '10px', width: '200px', color: 'red' }} >
                          {error1.ref}</div>
                      )}

                      <Button text='Проверить рефера!'
                        onClick={askRef}
                      />
                    </div>
                  </div>
                </>
                )}
                {newOrder.ref && (
                  <div style={{ width: '250px', display: 'flex', flexDirection: 'column', margin: '10px', alignItems: 'center', justifyContent: 'start' }}>
                    <h4 for="email">Рефер успешно добавлен!</h4>
                    <Button text={newOrder.ref.email}
                      background='#20b6e8'
                    // onClick={askRef}
                    />
                  </div>
                )}
                {/* </div> */}
                <div style={{ width: '250px', display: 'flex', flexDirection: 'column', margin: '10px', alignItems: 'center', justifyContent: 'start' }}>
                  <h4 style={{ textAlign: 'center' }}>Списать бонусы</h4>
                  <div className={style.promodiv}>
                    {!bonusTaked && (<>
                      {!asked && (<>
                        <Button text='Проверить бонусный баланс!' onClick={askBonus} />
                        {error1.email && (
                          <p style={{ color: "red", width: '200px' }}>{error1.email}</p>
                        )}
                      </>)}


                      {asked && (
                        <>
                          {timebonus > 0 && (<>
                            <p className={style.p}>У вас доступно <b>{timebonus} руб</b> для списания! Всего на вашем счету {bonusALL} руб</p>
                            <Button text='Списать бонусы!' background='#20b6e8' onClick={takeBonus} />
                          </>

                          )}
                          {timebonus == 0 && (
                            <p className={style.p}>На вашем счету нет бонусов!</p>

                          )}
                        </>
                      )}
                    </>)}
                    {bonusTaked && (<>
                      <p className={style.p}>В это заказе вы потратите <b>{bonus}</b> бонусных рублей! После оформления заказа у вас останется {bonusALL - bonus} руб.</p>

                    </>)}
                  </div>
                </div>
                <div style={{ width: '250px', display: 'flex', flexDirection: 'column', margin: '10px', alignItems: 'center', justifyContent: 'start' }}>
                  <h4 style={{ textAlign: 'center' }}>Промокод</h4>
                  <div className={style.promodiv}>
                    {/* {newOrder.DiliveryType == 'Самовывоз' && ( */}
                    <>
                      {!askedpromo && (
                        <>
                          {/* <label for="promo">Использовать Промокод</label> */}
                          <div className={style.gth}>
                            <input className={style.p} placeholder="Промокод" type="text" id="promo" name="promo"
                              onChange={(event) => {
                                setNewOrder({ ...newOrder, promocodeName: event.target.value });
                              }}
                            />
                            <Button text='Применить!' onClick={askPromo} />
                          </div>
                          {errorPromo && (
                            <p style={{ color: "red" }}>{errorPromo}</p>
                          )}
                        </>
                      )}

                    </>
                    {/* )} */}
                    {askedpromo
                      && promo > 0 && (

                        <p className={style.p}>Данный промокод дает <b>{promo}% скидки</b>!</p>

                      )}

                  </div>
                </div>




              </div>

              <h3 style={{ textAlign: 'center', margin: '10px' }}>Бонусы и Промокоды:</h3>
              <div style={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'row', margin: '10px', justifyContent: 'space-evenly', borderBottom: '2px gray solid' }}>
                {newOrder.DiliveryType == 'Самовывоз' && (<>
                  {/* <Button text='Оплатить заказ онлайн' onClick={SendOrderOnline} /> */}
                  <div>
                    <Button text='Оплатить заказ при получении' onClick={SendOrderOffline} />

                    <div>

                      {error && (() => {
                        // console.log(error);

                        // Создаем элементы списка как React-элементы
                        const listItems = Object.entries(error)
                          .filter(([key, value]) => value !== false) // Фильтруем свойства, где значение не false
                          .map(([key, value]) => <li key={key}>{value}</li>); // Создаем React-элементы списка

                        return (
                          <p>


                            <ul style={{ color: "red" }}>
                              {listItems}
                            </ul>
                          </p>
                        );
                      })()}

                    </div>
                  </div>

                </>

                )}
                {(newOrder.DiliveryType == 'Доставка по Калининграду' || newOrder.DiliveryType == 'Доставка по области') && (
                  <div>
                    <Button text='Оплатить заказ онлайн' onClick={SendOrderOnline} />
                  </div>


                )}
              </div>
            </div>







          </>
        )}

        {newOrderID && (
          <p>Спасибо за то, что вы с нами! Номер вашего заказа №{newOrderID}. Бонусные баллы начислятся вам на счет после получения покупки.</p>
        )}

      </div>
      {loading && (
        <Loading loading background="rgba(255, 165, 0,0.75)" loaderColor="#3498db"></Loading>
      )}
      {showDatePicker && (
        <ModalDatePicker
          DiliveryType={newOrder.DiliveryType}
          selectedDate={selectedDate}
          t={tomorrow}
          setSelectedDate={setSelectedDate}


        ></ModalDatePicker>
      )}

      {/* // } */}
    </>
  )

}
export default Order